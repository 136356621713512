<script setup lang="ts">
import type {
  VoixMediaFieldInterface,
} from '@voix/types'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    cardPosition: VoixSelectFieldInterface
    cardColor: VoixColorFieldInterface
    cardTextColor: VoixColorFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
  }
}>()
defineSlice({
  name: { label: 'Sandpiper Hero Image', group: 'sandpiper', layouts: ['Sandpiper'] },
  preview: 'SlicesSandpiperSandpiperHeroImage.jpg',
  fields: {
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        'default': { width: 600, height: 480 },
        'md': { width: 1024, height: 768 },
        'lg': { width: 1280, height: 960 },
        '2xl': { width: 1600, height: 1200 },
      },
      name: 'image',
      position: 'bg-center',
    },

    cardPosition: {
      type: 'select',
      label: 'Card Position',
      enabled: false,
      options: {
        left: 'Left',
        right: 'Right',
      },
      default: 'left',
    },

    cardColor: {
      type: 'color',
      label: 'Card Color',
      default: '#DF4D2C',
    },

    cardTextColor: {
      type: 'color',
      label: 'Card Text Color',
      default: '#FFFFFF',
    },

    title: {
      type: 'text',
      label: 'Title',
    },

    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: true,
    },

  },
})

const cardStyles = computed(() => {
  return {
    backgroundColor: props.fields.cardColor.value,
    color: props.fields.cardTextColor.value,
  }
})
</script>

<template>
  <div class="relative">
    <div class=" h-[300px] lg:h-[800px] overflow-hidden">
      <VoixMedia class="w-full h-full object-cover" :field="fields.image" />
    </div>

    <div
      v-if="fields.cardPosition.enabled"
      class="lg:absolute lg:inset-0 lg:flex lg:items-center lg:px-24" :class="{
        'lg:justify-start': fields.cardPosition.value === 'left',
        'lg:justify-end': fields.cardPosition.value === 'right',
      }"
    >
      <div class="p-8 lg:max-w-sm w-full" :style="cardStyles">
        <h1 class="text-3xl font-sandpiper-display">
          {{ fields.title.value }}
        </h1>
        <VoixWysiwyg class="mt-6 text-sm" :field="fields.copy" />
        <VoixLink
          :field="fields.link"
          class="mt-4 text-sm inline-block px-4 py-2 bg-white text-shocking font-sandpiper-display uppercase font-medium rounded-sm"
        />
      </div>
    </div>
  </div>
</template>
